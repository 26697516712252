import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Button = styled.div`
  margin-top: 16px;

  @media ${device.desktopLG} {
    .react-multi-carousel-item {
      position: relative;
      right: 20px;
    }
  }

  button, a {
    text-transform: none;
  }

  button {
    width: 100%;
    max-width: 100%;
  }

`

export const Container = styled.div`
  margin: auto;

  display: flex;
  flex-direction: column;

  gap: 24px;

  padding: 0 20px 0 0;

  @media ${device.tablet} {
    flex-direction: row;
    gap: 16px;

    position: relative;
    right: 20px;
    padding: 0;

    > div{
      flex-basis: 50%;
    }
  }

  @media ${device.desktopXL} {
    padding-right: 60px;
    gap: 11px;

    > div{
      flex-basis: auto;
    }
  }
`

export const ContentImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-size: 10px;
    line-height: 12px;
    color: #6a6c72;
  }
`

export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29.05px;
    letter-spacing: 0px;
    margin: 0;

    color: #161616;
  }

  .card-flag {
    img {
      width: 100%;
    }
  }

  button {
    text-transform: none; 
  }

  @media ${device.tablet} {
    gap: 22px;
    width: 390px;

    h3{
      font-size: 20px;
      line-height: 24.2px;
    }
  }

  @media ${device.desktopLG} {
    .card-flag {
      img{
        height: 32px;
        width: auto;
      }
    }
  }

  @media ${device.desktopXL} {
    gap: 32px;

    h3{
      font-size: 24px;
      line-height: 29.05px;
    }
  }
`

export const ContentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 220px;

  >div{
    display: flex;
    gap: 1rem;

    span{
      display: block;

      font-weight: 400;
      font-size: 16px;
      line-height: 19.36px;
      letter-spacing: 0px;
    }
  }

  @media ${device.tablet} {
    gap: 12px;
    height: 200px;

    >div {
      gap: 5px;

      span{
        font-size: 12px;
        line-height: 14.52px;
      }
    }
  }

  @media ${device.desktopLG} {
    height: 170px;
  }

  @media ${device.desktopXL} {
    gap: 18px;
    height: 250px;

    >div {
      gap: 8px;

      span{
        font-size: 16px;
        line-height: 19.36px;
      }
    }
  }

  @media ${device.desktopXXXL} {
    height: 190px;
  }
`
