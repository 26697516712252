import { device } from 'src/styles/breakpoints'
import { orange, white } from 'src/styles/colors'
import styled from 'styled-components'

const heroBannerMobile = 'https://central-imagens.bancointer.com.br/images-without-small-versions/inter-pag-dobra6-mobile/image.webp'
const heroBannerTablet = 'https://central-imagens.bancointer.com.br/images-without-small-versions/inter-pag-dobra6-web/image.webp'

export const Section = styled.section`
  h2 {
    font-size: 32px;
    font-family: 'Citrina', Helvetica, sans-serif;
    color: white;
    margin-bottom: 24px;
  } 

  align-items: center;
  display: flex;
  background-image: url(${heroBannerMobile});
  height: calc(100vh - 70px);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center right;
  padding-top: 40px;
  padding-bottom: 40px;
  height: 600px;

  @media ${device.tablet} {
    background-image: url(${heroBannerTablet});
    height: 500px;
    h2 {
      font-size: 24px;
    }
    p {
      font-size: 12px;
      line-height: 16px;
    }
  }

  @media ${device.desktopLG} {
    height: 500px;
    h2 {
      font-size: 28px;
    }
    p {
      font-size: 14px;
      line-height: 18px;
    }
  }

  @media ${device.desktopXL} {
    height: 700px;
    h2 {
      font-size: 40px;
    }
    p {
      font-size: 20px;
      line-height: 28px;
    }
  }
`

export const Steps = styled.div`
  p {
    color: white;
    font-size: 16px;
  }
`

export const Step = styled.div`
  background-color: ${orange.extra};
  border-radius: 50%;
  color: white;
  font-weight: 600;
  min-width: 30px;
  height: 30px;
  padding-left: 10px;
  padding-top: 3px;
  margin-right: 8px;
  margin-bottom: 30px;
`
export const CTA = styled.button`
  font-size: 20px;
  line-height: 22px;
  color: ${white};
  display: block;
  float: left;
  background: none;
  margin-top: 20px;

  &:hover {
    color: ${white};
    opacity: 0.9;
  }
  @media ${device.desktopLG} {
    margin-top: 20px;
    font-size: 18px;
    line-height: 22px;
  }
  @media ${device.desktopXL} {
    margin-top: 10px;
    margin-bottom: 0;
  }
  @media ${device.desktopXXXL} {
    font-size: 32px;
    line-height: 38px;
  }
`
