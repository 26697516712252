import { device } from 'src/styles/breakpoints'
import { orange, white } from 'src/styles/colors'
import styled from 'styled-components'

const heroBannerMobile = 'https://central-imagens.bancointer.com.br/images-without-small-versions/inter-pag-dobra7-mobile/image.webp'

export const Section = styled.section`
   .title {
    font-size: 48px;
    font-family: 'Citrina', Helvetica, sans-serif;
    color: white;

    @media ${device.tablet} {
      font-size: 40px;
    }

    @media ${device.desktopLG} {
      font-size: 56px;
    }

    @media ${device.desktopXL} {
      font-size: 80px;
    }
  }

  align-items: flex-end;
  display: flex;
  background-image: url(${heroBannerMobile});
  min-height: calc(100vh - 70px);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center right;
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
  overflow: hidden;

  @media ${device.tablet} {
    background: none;
    align-items: center;
    min-height: fit-content;
    padding-top: 0;
    min-height: 420px;
  }

  @media ${device.desktopLG} {
    min-height: 550px;
    align-items: center;
  }

  @media ${device.desktopXL} {
    min-height: calc(100vh - 70px);
    align-items: center;
  }

  div {
    z-index: 2;
  }

  video {
    z-index: 1;
    position: absolute;
    width: 100%;
    top: 0;
  }
`

export const Button = styled.button`
  width: 100%;
  height: 48px;
  background: ${orange.extra};
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${white};
  margin-top: 32px;
  margin-bottom: 32px;
  border: none;
  max-width: 456px;
`
export const CTA = styled.button`
  font-size: 20px;
  line-height: 22px;
  color: ${white};
  display: block;
  background: none;
  margin-top: 20px;

  &:hover {
    color: ${white};
    opacity: 0.9;
  }
  @media ${device.tablet} {
    margin-bottom: 0;

  }
  @media ${device.desktopLG} {
    margin-top: 20px;
    font-size: 24px;
    line-height: 28px;
  }
  @media ${device.desktopXL} {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 32px;
    line-height: 38px;
  }
`
