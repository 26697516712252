import React from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { orange } from 'src/styles/colors'
import data from '../../assets/data/OnePagPossibilities.json'
import { BenefitsProps } from './types'
import { Section, Card } from './style'
import ImageWebp from 'src/components/ImageWebp'

export const OnePagPossibilities = () => {
  return (
    <Section>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div
            className='col-12 mb-2'
          >
            <h2>
              Uma maquininha de cartão, centenas de possibilidades.
            </h2>
            <p>
              Garanta taxas competitivas e que são de acordo com a realidade e segmento de atuação do seu negócio. Com a antecipação de recebíveis, receba suas vendas até a manhã seguinte, com taxa de antecipação
              <strong> a partir de 1,99% a.m.</strong>
            </p>
          </div>

          <div className='d-flex justify-content-center w-100 ajust-image'>
            <ImageWebp
              className='d-block d-md-none'
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/inter-pag-dobra5/image.webp'
              altDescription=''
            />
            <ImageWebp
              className='d-none d-md-block'
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/inter-pag-dobra5-web/image.webp'
              altDescription=''
              arrayNumbers={[ 800, 900, 1000, 1000, 1000 ]}
            />
          </div>

          <div
            className='col-12 mt-4'
          >
            <div className='row'>
              {data.map((item: BenefitsProps) => (
                <div className='col-12 col-md-3' key={item.title}>
                  <Card>
                    <div className='d-block'>
                      <OrangeIcon
                        icon={item.icon}
                        color={orange.extra}
                        size='MD'
                      />
                      <h3>{item.title}</h3>
                    </div>
                    <p>{item.description}</p>
                  </Card>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}
