import styled from 'styled-components'

import { device } from 'src/styles/breakpoints'
import { brand, grayscale, white } from 'src/styles/colors'

export const Section = styled.section`
  h2 {
    font-family: 'Citrina', Helvetica, sans-serif;
    font-size: 28px;
    line-height: 32px;
    font-weight: 700;
    color: ${grayscale[500]};

    @media ${device.desktopLG} {
      font-size: 40px;
    }
  }
  padding-top: 40px;
  padding-bottom: 40px;

  @media ${device.desktopLG} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media ${device.desktopLG} {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  .position-0 {
    max-width: 210px;
  }

  img{
    width: 100%;
  }
`

export const StepNumber = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #E9A575;
  width: 44px;
  height: 44px;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: ${white};

  @media ${device.desktopLG} {
    display: flex;
  }
`

export const Fees = styled.div`
  div {
    border-bottom: 1px solid ${brand.primary};
    margin-bottom: 24px;
  }
`
