import React from 'react'
import { ImgWebp } from 'src/styles/imgWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import bandeirasCartoes from '../../assets/images/bandeirasCartoes.png'
import IconsSwitch from '../../../../../components/IconsSwitch/_index'

import { MachineBenefictsProps, MachineProps } from '../../types'

import { Container, ContentImage, ContentInfo, ContentList } from './style'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

type MPosProps = {
  setIsModalOpen: (isModalOpen: boolean) => void;
  dataInterPag: MachineProps;
  dataLayer: IDataLayerParams;
};

export const InterPag = ({ setIsModalOpen, dataInterPag, dataLayer }: MPosProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Container>
      <ContentImage>
        <ImgWebp
          breakPointsSize={{ sm: '195px', md: '138px', xl: '230px' }}
          breakPointsHeight={{ sm: '350px', md: '258px', xl: '430px' }}
          src={dataInterPag?.image}
          alt={dataInterPag?.altImage}
        />
        <span>imagem ilustrativa</span>
      </ContentImage>

      <ContentInfo>
        <h3>{dataInterPag?.title}</h3>

        <div className='card-flag'><img src={bandeirasCartoes} /></div>

        <ContentList>
          {dataInterPag?.beneficts?.map((item: MachineBenefictsProps) => (
            <div key={item.icon}>
              <span>
                <IconsSwitch
                  lib='interco'
                  icon={item.icon}
                  width='24' height='24'
                  color='#FF7A00'
                  customPath={item.customPath}
                />
              </span>
              <span>{item.description}</span>
            </div>
          ))}
        </ContentList>

        <button
          className='btn btn--lg btn-orange--extra fs-14 fw-700 rounded-3 ml-lg-0'
          onClick={() => {
            setIsModalOpen(true)
            sendDatalayerEvent(dataLayer)
          }}
        >
          Solicitar orçamento
        </button>
      </ContentInfo>
    </Container>
  )
}
