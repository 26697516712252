import styled from 'styled-components'

import { device } from 'src/styles/breakpoints'
import { brand } from 'src/styles/colors'

export const Section = styled.section`
  overflow: hidden;
  
  .react-multi-carousel-list {
    overflow: visible;
  }

  h2 {
    font-family: 'Citrina', Helvetica, sans-serif;

    @media ${device.desktopXL} {
      position: relative;
    }
    @media ${device.desktopXXLL} {
      position: relative;
    }
  }

  h3 {
    font-family: 'Inter', Helvetica, sans-serif;
  }

  @media ${device.tablet} {
    .react-multiple-carousel__arrow--right {
      right: calc(30% + 1px);
    }
    .react-multiple-carousel__arrow--left {
      left: calc(30% + 1px);
    }
  }

  background-color: ${brand[100]};
  padding-top: 40px;
  padding-bottom: 40px;

  @media ${device.tablet} {
    padding-top: 56px;
    padding-bottom: 56px;
  }

  @media ${device.desktopXL} {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  ul {

    li {
      margin: 0;

      &:first-child {
        button {
          margin-right: 32px;
        }
      }

      button {
        margin-bottom: 0 !important;
      }

      @media ${device.desktopXL} {
        &:first-child {
          button {
            margin-right: 16px;
          }
        }
      }
    }
  }

  .card-machine{
    background-color: #fff;
    height: 100%;
    div {
      height: 100%;
    }
  }
`

export const Button = styled.div`
  margin-top: 16px;

  button,
  a {
    text-transform: none;
  }

  button {
    width: 100%;
    max-width: 100%;
  }
`

export const POSGranito = styled.div`
  width: 100%;
  display: block;

`

export const MPOSGranito = styled.div`

`
