import React, { useState } from 'react'

import Tab from 'src/components/Tab'
import { orange } from 'src/styles/colors'

import { Section } from './style'
import { CarouselSteps } from './_CarouselSteps'
import { MEI } from './_MEI'

const AVAILABLE_TABS: string[] = [ 'Pessoa Jurídica', 'Pessoa Física e MEI' ]

type Props = {
  setIsModalOpen: (isModalOpen: boolean) => void;
};

export const StepByStep = ({ setIsModalOpen }: Props) => {
  const [ activeTab, setActiveTab ] = useState(0)

  return (
    <Section>
      <div className='container'>
        <div className='row'>
          <div className='col-12 d-none d-md-block text-center mb-4'>
            <h2 className={`${activeTab === 0 ? 'd-none d-md-block' : 'd-none'}`}>
              Maquininha com menor taxa? É a Inter Pag
            </h2>
            <h2 className={`${activeTab === 1 ? 'd-none d-md-block' : 'd-none'}`}>
              Taxas para clientes PF e MEI
            </h2>
          </div>
          <div className='col-12'>
            <Tab
              items={AVAILABLE_TABS}
              styles='fs-14 lh-17 fs-xl-16 lh-xl-19 fw-700'
              border
              activeColor={orange.extra}
              setTabActive={setActiveTab}
              classDiv='tab'
              isH3
            >
              {activeTab === 0 && (
                <CarouselSteps setIsModalOpen={setIsModalOpen} />
              )}
              {activeTab === 1 && (
                <MEI />
              )}
            </Tab>
          </div>
        </div>
      </div>
    </Section>
  )
}
