import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type Props = {
  setIsModalOpen: (isModalOpen: boolean) => void;
};

export const CarouselSteps = ({ setIsModalOpen }: Props) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <div className='row align-items-md-center'>
      <div className='col-12 col-md-6 order-2 order-md-1'>
        <h2 className='fs-34 lh-30 fs-lg-24 lh-lg-30 fs-xl-40 lh-xl-50 fw-600 mt-5 mt-md-0 text-grayscale--500'>
          Cliente PJ
        </h2>
        <p className='fs-16 lh-19 fs-xl-18 lh-xl-22 fw-400 mb-5 mt-3'>
          Simule suas taxas personalizadas de acordo com o seu negócio diretamente no Super App Inter Empresas. A partir de <strong>0,88% no débito e 1,60% no crédito!</strong>
        </p>
        <button
          className='btn btn--lg btn-orange--extra fs-14 text-none fw-700 rounded-3 ml-lg-0 d-none d-md-block'
          onClick={() => {
            setIsModalOpen(true)
            sendDatalayerEvent({
              section: 'dobra_03',
              section_name: 'Maquininha com menor taxa? É a Inter Pag',
              element_action: 'click button',
              element_name: 'Simular agora',
            })
          }}
        >
          Simular agora
        </button>
      </div>
      <div className='col-12 col-md-6 order-1 order-md-2 d-flex justify-content-center'>
        <ImageWebp
          pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/rebranding-maquininha-carousel1-better-quality/image.webp'
          altDescription=''
          arrayNumbers={[ 300, 400, 400, 500, 550 ]}
        />

      </div>
      <div className='col-12 order-3'>
        <button
          className='btn btn--lg btn-orange--extra fs-14 fw-700 rounded-3 ml-lg-0 d-block d-md-none text-none'
          onClick={() => {
            setIsModalOpen(true)
            sendDatalayerEvent({
              section: 'dobra_03',
              section_name: 'Maquininha com menor taxa? É a Inter Pag',
              element_action: 'click button',
              element_name: 'Simular agora',
            })
          }}
        >
          Simular agora
        </button>
      </div>
    </div>
  )
}
