import React from 'react'
import { Section } from './style'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import DataInterPag from '../../assets/data/interPag.json'
import { InterPag } from '../../components/InterPag/_index'
import { MachineProps } from '../../types'

type MachinesProps = {
  setIsModalOpen: (isModalOpen: boolean) => void;
};

export const Machines = ({ setIsModalOpen }: MachinesProps) => {
  return (
    <Section id='cartao-inter'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 mb-4 mb-xl-5'>
            <h2
              className='fs-24 lh-30 fs-lg-28 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 text-center mb-4 mb-lg-0'
            >
              Sua empresa com a <span className='d-block d-md-inline'>maquininha perfeita!</span>
            </h2>
          </div>
          <div className='col-12'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 20 }}
              md={{ items: 2 }}
              lg={{ items: 2, partialVisibilityGutter: 30 }}
              xl={{ items: 2, partialVisibilityGutter: 27 }}
              customDotColor='#EA7100'
            >
              {DataInterPag?.map((item: MachineProps) => (
                <div key={item?.title}>
                  <InterPag
                    dataInterPag={item}
                    setIsModalOpen={setIsModalOpen}
                    dataLayer={{
                      section: 'dobra_02',
                      section_name: 'Sua empresa com a maquininha perfeita!- mPOS Inter Pag',
                      element_action: 'click button',
                      element_name: 'Solicitar orçamento',
                    }}
                  />
                </div>
              )) }
            </DefaultCarousel>

          </div>
        </div>
      </div>
    </Section>
  )
}
